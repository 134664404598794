import { AspectRatio, Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver, Elements } from '@kontent-ai/delivery-sdk';
import cacheData from 'memory-cache';
import { ContentPage, ContentPageActor, NavigationCategory, NavigationLanguageItem } from 'models';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import slugify from 'slugify';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { internalPages } from 'utils/getInternalPage';
import { handleLanguageChange } from 'utils/handleLocaleChange';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { LinkType } from '../../lib/enums/LinkType';
import Breadcrumb from '../base/Breadcrumb';
import { Link as BaseLink } from '../base/Link';
import { MegaMenuActiveTabProps } from './NavBar';

interface HighlightedItemProps {
  image: ReturnType<typeof imageFormatter>;
  headline: string;
  description: string;
  url: string;
  linkText: string;
  featured?: string;
}

interface MegaMenuProps {
  megaMenuProps: MegaMenuActiveTabProps;
  setMegaMenuIndexMobile: React.Dispatch<React.SetStateAction<number>>;
  setIsMegaMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
  theme?: any;
}

export const MegaMenu: FC<MegaMenuProps> = ({ megaMenuProps, setMegaMenuIndexMobile, setIsMegaMenuActive, theme }) => {
  const translations = withTranslations();

  const router = useRouter();
  const [cookie, setCookie] = useCookies(['NEXT_LOCALE']);
  const [highlightedItem, setHighlightedItem] = useState<HighlightedItemProps>();
  const [subpages, setSubpages] = useState(megaMenuProps.subpages);
  const [category, setCategory] = useState({
    title: megaMenuProps.categoryTitle,
    description: megaMenuProps.categoryDescription,
  });
  const [activeExploreBy, setActiveExploreBy] = useState<string>();
  const [exploreByPages, setExploreByPages] = useState<ContentPage[]>();
  const [exploreByOverviewPage, setExploreByOverviewPage] = useState('');
  const [linkToOverviewPage, setLinkToOverviewPage] = useState('');
  const [isChangingViews, setIsChangingViews] = useState(false);
  const [isChangingViewsExploreBy, setIsChangingViewsExploreBy] = useState(false);
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;

  useEffect(() => {
    // -- Timeout is for fade-in/out animation, old text is visible for 0.2s --
    // -- Reset the explore by pages & third column --
    setLinkToOverviewPage(megaMenuProps.linkToOverview);
    setActiveExploreBy(undefined);
    setExploreByPages([]);
    subpages[0].system.type !== 'navigation_category' && setExploreByOverviewPage('');
    // -- Fetch the highlighted article data (images, text, etc) --
    if (megaMenuProps.highlightedArticle) {
      const HLA = megaMenuProps.highlightedArticle as ContentPage | ContentPageActor;
      // TODO: (Noah) See if there is a way to check if there is a navigation description or not
      // Currently it's never empty...
      const page = megaMenuProps.highlightedArticle as ContentPage;
      const featured = page.elements.featured?.value[0]?.codename;
      setHighlightedItem({
        image: imageFormatter(HLA.elements.summaryImageFrontify?.value),
        headline: HLA.elements.summaryTitle.value,
        description: createRichTextHtmlResolver(nodeParser).resolveRichText({
          element: HLA.elements.summaryDescription as Elements.RichTextElement,
        }).html,
        url:
          megaMenuProps.highlightedArticle.elements.partOfCampaignPage?.value.length > 0 &&
          HLA.system.type === 'content_page'
            ? hrefLink(
                HLA as ContentPage,
                (slug as unknown as string)?.split('/'),
                true,
                translations.locale,
                translations,
              )
            : megaMenuProps.highlightedArticle.elements.partOfCampaignPage?.value.length == 0 &&
              HLA.system.type === 'content_page'
            ? normalize(HLA as ContentPage, undefined, undefined, false, translations)?.[HLA.system.codename] ?? '/'
            : internalPages(translations, (HLA as ContentPageActor).elements.internalCtaLink.value[0]?.codename) ??
              (HLA as ContentPageActor).elements.externalCtaLink.value,
        linkText:
          HLA.system.type === 'content_page'
            ? translations.common('readMore')
            : (HLA as ContentPageActor).elements.ctaText.value,
        featured: featured,
      });
    } else {
      // -- Case there is no highlighted article, the elements wont be rendered at all --
      setHighlightedItem(undefined);
    }
    // -- UseStates for updating elements when clicking on a new category --
    setSubpages(megaMenuProps.subpages);
    setCategory({ title: megaMenuProps.categoryTitle, description: megaMenuProps.categoryDescription });
    setIsChangingViews(false);
  }, []);
  useEffect(() => {
    if (megaMenuProps.categoryTitle !== category.title) setIsChangingViews(true);
    // -- Timeout is for fade-in/out animation, old text is visible for 0.2s --

    setTimeout(
      () => {
        // -- Reset the explore by pages & third column --
        setLinkToOverviewPage(megaMenuProps.linkToOverview);
        setActiveExploreBy(undefined);
        setExploreByPages([]);
        subpages[0].system.type !== 'navigation_category' && setExploreByOverviewPage('');
        // -- Fetch the highlighted article data (images, text, etc) --
        if (megaMenuProps.highlightedArticle) {
          const HLA = megaMenuProps.highlightedArticle as ContentPage | ContentPageActor;
          // TODO: (Noah) See if there is a way to check if there is a navigation description or not
          // Currently it's never empty...
          const page = megaMenuProps.highlightedArticle as ContentPage;
          const featured = page.elements.featured?.value[0]?.codename;
          setHighlightedItem({
            image: imageFormatter(HLA.elements.summaryImageFrontify?.value),
            headline: HLA.elements.summaryTitle.value,
            description: createRichTextHtmlResolver(nodeParser).resolveRichText({
              element: HLA.elements.summaryDescription as Elements.RichTextElement,
            }).html,
            url:
              megaMenuProps.highlightedArticle.elements.partOfCampaignPage?.value.length > 0 &&
              HLA.system.type === 'content_page'
                ? hrefLink(
                    HLA as ContentPage,
                    (slug as unknown as string)?.split('/'),
                    true,
                    translations.locale,
                    translations,
                  )
                : megaMenuProps.highlightedArticle.elements.partOfCampaignPage?.value.length == 0 &&
                  HLA.system.type === 'content_page'
                ? normalize(HLA as ContentPage, undefined, undefined, false, translations)?.[HLA.system.codename] ?? '/'
                : internalPages(translations, (HLA as ContentPageActor).elements.internalCtaLink.value[0]?.codename) ??
                  (HLA as ContentPageActor).elements.externalCtaLink.value,
            // url:
            //   HLA.system.type === 'content_page'
            //     ? normalize(HLA as ContentPage, undefined, undefined, false, translations)?.[HLA.system.codename] ?? '/'
            //     : internalPages(translations, (HLA as ContentPageActor).elements.internalCtaLink.value[0]?.codename) ??
            //       (HLA as ContentPageActor).elements.externalCtaLink.value,
            linkText:
              HLA.system.type === 'content_page'
                ? translations.common('readMore')
                : (HLA as ContentPageActor).elements.ctaText.value,
            featured: featured,
          });
        } else {
          // -- Case there is no highlighted article, the elements wont be rendered at all --
          setHighlightedItem(undefined);
        }
        // -- UseStates for updating elements when clicking on a new category --
        setSubpages(megaMenuProps.subpages);
        setCategory({ title: megaMenuProps.categoryTitle, description: megaMenuProps.categoryDescription });
        setIsChangingViews(false);
      },
      // -- Not on mobile tho --
      window.innerWidth >= 1024 ? 200 : 0,
    );
  }, [megaMenuProps]);

  return (
    <>
      <Flex
        boxShadow='4px 4px 4px 0px #d4d7d9 inset'
        bg={'white'}
        w={{ base: 'unset', lg: '100vw' }}
        // w='100%'
        // maxW={{base: "1440px",md:moduleMaxWidth, lg: moduleMaxWidth}}
        maxW={moduleMaxWidth}
        // h='-moz-max-content'
        // bg='white'
        p={{ base: '0', lg: 's' }}
        opacity={{ base: '1', lg: !isChangingViews ? '1' : '0' }}
        transition='opacity 0.3s'
        id='RambollMegaMenu'
        h={{ base: '100%', lg: 'auto' }}
        sx={{
          '@media screen and (max-width: 1024px)': {
            h: '100%',
          },
        }}
        //  pt='20px'
        // sx={{
        //   base: '100rem',

        // }}
      >
        {/* ------------ First column ------------ */}
        <Flex
          direction='row'
          display={{ base: 'none', lg: 'flex' }}
          w={{ base: '100vw', lg: '50%' }}
          h={{ base: '100%', lg: 'auto' }}
          // TODO: (Noah) Figure out how to handle the overflow
          // overflowY='scroll'
          position={'relative'}
          overflow='hidden'
          pr='s'
          borderRight='1px solid'
          borderColor='mountain.10'
          overflowY='scroll'
          sx={{
            '@media screen and (max-width: 1024px)': {
              h: '100%',
            },
            '::-webkit-scrollbar': {
              width: '4px',
            },
            '::-webkit-scrollbar-track': {
              width: '6px',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'mountain.20',
              borderRadius: '0',
            },

            scrollbarColor: 'var(--chakra-colors-mountain-20) white',
            scrollbarWidth: 'thin',
          }}
        >
          <Box pr='xs' w='50%'>
            <Text pb='xs' textStyle={'desktop.h4'}>
              {category.title}
            </Text>
            <Box pb='xs' textStyle={'mobile.body.s'} dangerouslySetInnerHTML={{ __html: category.description }}></Box>
          </Box>
          {highlightedItem && (
            <Box as='article' w='50%'>
              <AspectRatio ratio={16 / 9}>
                <Image
                  style={{ paddingTop: '20px' }}
                  src={highlightedItem.image.src}
                  alt={highlightedItem.image.alt}
                  width={highlightedItem.image.width}
                  height={highlightedItem.image.height}
                />
              </AspectRatio>
              {highlightedItem.featured == 'yes' && (
                <Box
                  // textStyle={{ base: 'mobile.h6', lg: 'desktop.h6' }}
                  pt='xs'
                  as='h3'
                  display={{ base: 'none', lg: 'block' }}
                  // pb='xs'
                >
                  {translations.content_type('featured')}
                </Box>
              )}
              {highlightedItem?.headline !== '' && (
                <Text pt='xs' textStyle={'desktop.h5'}>
                  {highlightedItem.headline}
                </Text>
              )}
              {highlightedItem?.description !== '' && (
                <>
                  <Box
                    textStyle={'desktop.body.l'}
                    pt='xs'
                    pb='xs'
                    // dangerouslySetInnerHTML={{ __html: highlightedItem.description }}
                  ></Box>
                  <BaseLink
                    locale={router.locale}
                    href={highlightedItem.url ?? '/'}
                    colorScheme='cyan.web'
                    type={LinkType.SecondaryButton}
                    iconName='chevronRightForLink'
                    onClick={() => setIsMegaMenuActive(false)}
                  >
                    {/* TODO: This needs to come from Kontent.ai & have HREF */}
                    {highlightedItem.linkText}
                  </BaseLink>
                </>
              )}
            </Box>
          )}
        </Flex>
        {/* ------------ First column END ------------ */}
        {/* ------------ Second column (HA / Featurend content)------------ */}
        {/* <Flex
          w={{ base: '100vw', lg: '25%' }}
          display={{ base: 'none', lg: 'flex' }}
          position={'relative'}
          h={{ base: '100%', lg: 'auto' }}
          borderRight={{ base: 'none', lg: '1px solid' }}
          borderColor={{
            base: 'none',
            lg: '#D4D7D9',
          }}
          // pb={{ base: 'l', lg: 'unset' }}
          // pl={{ base: 'xs', lg: 's' }}
          pr={{ base: 'xs', lg: 's' }}
          sx={{
            '@media screen and (max-width: 1024px)': {
              h: '100%',
            },
            '::-webkit-scrollbar': {
              width: '4px',
            },
            '::-webkit-scrollbar-track': {
              width: '6px',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'mountain.20',
              borderRadius: '0',
            },

            scrollbarColor: 'var(--chakra-colors-mountain-20) white',
            scrollbarWidth: 'thin',
            scrollbarGutter: 'stable both-edges',
          }}
        >
          {highlightedItem && (
            <Box as='article'>
              <AspectRatio ratio={16 / 9}>
                <Image
                  // style={{ paddingTop: '20px' }}
                  src={highlightedItem.image.src}
                  alt={highlightedItem.image.alt}
                  width={highlightedItem.image.width}
                  height={highlightedItem.image.height}
                />
              </AspectRatio>
              {highlightedItem.featured == 'yes' && (
                <Box
                  // textStyle={{ base: 'mobile.h6', lg: 'desktop.h6' }}
                  pt='xs'
                  as='h3'
                  display={{ base: 'none', lg: 'block' }}
                  // pb='xs'
                >
                  {translations.content_type('featured')}
                </Box>
              )}
              {highlightedItem?.headline !== '' && (
                <Text pt='xs' textStyle={'desktop.h5'}>
                  {highlightedItem.headline}
                </Text>
              )}
              {highlightedItem?.description !== '' && (
                <>
                  <Box textStyle={'desktop.body.l'} pt='xs' pb='xs'></Box>
                  <BaseLink
                    locale={router.locale}
                    href={highlightedItem.url ?? '/'}
                    colorScheme='cyan.web'
                    type={LinkType.SecondaryButton}
                    iconName='chevronRightForLink'
                    onClick={() => setIsMegaMenuActive(false)}
                  >
                    {/* TODO: This needs to come from Kontent.ai & have HREF */}
        {/* {highlightedItem.linkText}
                  </BaseLink>
                </>
              )}
            </Box>
          )}
        </Flex> */}
        {/* ------------ Second column END ------------ */}
        {/* ------------ Third column------------ */}
        <Box
          w={{ base: '100vw', lg: subpages[0].system.type === 'navigation_category' ? '25%' : '50%' }}
          borderRight={{
            base: 'none',
            lg: subpages[0]?.system.type === 'navigation_category' ? '1px solid' : undefined,
          }}
          borderColor={{
            base: 'none',
            lg: subpages[0].system.type === 'navigation_category' ? '#D4D7D9' : undefined,
          }}
          pb={{ base: 'l', lg: 'unset' }}
          pl={{ base: 'xs', lg: 's' }}
          pr={{ base: 'xs', lg: 'unset' }}
          h={{ base: '100%', lg: 'auto' }}
          sx={{
            '@media screen and (max-width: 1024px)': {
              h: '100%',
            },
          }}
        >
          <Box pb='xs' borderBottom={'1px solid'} borderColor='#D4D7D9' display={{ base: 'block', lg: 'none' }} pt='xs'>
            <Breadcrumb
              name={megaMenuProps.categoryTitle}
              onClick={() => {
                setMegaMenuIndexMobile(0);
              }}
            />
          </Box>
          <UnorderedList
            className='third'
            listStyleType={'none'}
            margin='0'
            pt={{ base: 'xs', lg: 'unset' }}
            h={{ base: 'calc(100% - 50px)', lg: 'auto' }}
            overflowY={'auto'}
            // alignSelf={'stretch'}
            sx={{
              '::-webkit-scrollbar': {
                width: '4px',
              },
              '::-webkit-scrollbar-track': {
                width: '6px',
              },
              '::-webkit-scrollbar-thumb': {
                background: 'mountain.20',
                borderRadius: '0',
              },

              scrollbarColor: 'var(--chakra-colors-mountain-20) white',
              scrollbarWidth: 'thin',
              scrollbarGutter: 'stable both-edges',
            }}
          >
            {subpages[0]?.system.type === 'navigation_category' && (
              <Text as='h3' pb='xs' display={{ base: 'none', lg: 'block' }}>
                {translations.common('exploreBy')}:
              </Text>
            )}
            {category.title !== 'Insights' &&
            category.title != 'Artikkelit' &&
            category.title != 'Innsikt' &&
            category.title != 'Insikter' ? (
              subpages?.map((subpage: any, index: number) => {
                const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
                const url =
                  subpage.system.type === 'content_page'
                    ? hrefLink(
                        subpage,
                        (slug as unknown as string)?.split('/'),
                        true,
                        translations.locale,
                        translations,
                      )
                    : subpage.system.type === 'navigation_language_item'
                    ? `${(subpage as NavigationLanguageItem).elements.languageCodename.value}`
                    : subpage.system.type === 'content_page_actor'
                    ? internalPages(
                        translations,
                        (subpage as ContentPageActor).elements.internalCtaLink.value[0]?.codename,
                      ) ?? (subpage as ContentPageActor).elements.externalCtaLink.value
                    : '/';
                return (
                  <ListItem
                    key={index}
                    // -- Gives different colors to the "Explore by" categories when you press them --
                    color={{
                      base: 'unset',
                      lg:
                        subpage.system.type === 'navigation_category' &&
                        activeExploreBy === subpage.elements.title.value
                          ? 'cyan.web'
                          : 'unset',
                    }}
                    pb='s'
                  >
                    {subpage.system.type === 'navigation_category' ? (
                      // -- If there are "Explore by" elements present --
                      <Flex
                        alignItems='center'
                        justifyContent={'space-between'}
                        gap='s'
                        sx={{
                          _after: {
                            content: '""',
                            borderBottom: '1px solid',
                            borderColor: 'cyan.web',
                            //
                            w: '100%',
                            display: {
                              base: 'none',
                              lg: activeExploreBy === subpage.elements.title.value ? 'block' : 'none',
                            },
                          },
                        }}
                      >
                        <Box
                          as='button'
                          _hover={{ color: 'cyan.web' }}
                          textStyle={{ base: 'mobile.h3', lg: 'desktop.h4' }}
                          onClick={() => {
                            const timeout = window.innerWidth >= 1024 ? 200 : 0;
                            setActiveExploreBy((subpage as NavigationCategory).elements.title.value);
                            // -- Set explore by pages & animation --
                            if (
                              (subpage as NavigationCategory).elements.linkedPages.linkedItems &&
                              exploreByPages !== (subpage as NavigationCategory).elements.linkedPages.linkedItems
                            ) {
                              setIsChangingViewsExploreBy(true);
                              setTimeout(() => {
                                setExploreByPages((subpage as NavigationCategory).elements.linkedPages.linkedItems);
                                (subpage as NavigationCategory).elements.linkToOverviewPage.value[0]
                                  ? setExploreByOverviewPage(
                                      (subpage as NavigationCategory).elements.linkToOverviewPage.value[0].codename,
                                    )
                                  : setExploreByOverviewPage('');
                                setIsChangingViewsExploreBy(false);
                              }, timeout);
                            } else if (!(subpage as NavigationCategory).elements.linkedPages.linkedItems) {
                              setTimeout(() => {
                                setIsChangingViewsExploreBy(true);
                                setExploreByOverviewPage('');
                                setExploreByPages([]);
                                setIsChangingViewsExploreBy(false);
                              });
                            }
                            setMegaMenuIndexMobile(2);
                          }}
                        >
                          {(subpage as NavigationCategory).elements.title.value}
                        </Box>
                      </Flex>
                    ) : (
                      <Flex
                        alignItems='center'
                        justifyContent={'space-between'}
                        gap='s'
                        sx={{
                          _after: {
                            content: '""',
                            borderBottom: '1px solid',
                            borderColor: 'cyan.web',
                            //
                            w: '100%',
                            display: {
                              base: 'none',
                              lg: 'none',
                            },
                          },
                        }}
                      >
                        <Box>
                          {/* TODO: (Ante) Look into overview pages locale change to change filters as well from url => should be done in variants */}
                          {subpage.system.type === 'navigation_language_item' ? (
                            <BaseLink
                              iconName='chevronRight'
                              type={LinkType.Menu}
                              href={'/[...slug].tsx'}
                              as={megaMenuProps.variants?.[url] || `/${url}`}
                              locale={url}
                              onClick={() => {
                                handleLanguageChange(url, cookie, setCookie);
                                //  removeInitialLocaleCookie('INITIAL_LOCALE');
                                setIsMegaMenuActive(false);
                              }}
                            >
                              {(subpage as NavigationLanguageItem).elements.title.value}
                            </BaseLink>
                          ) : (
                            <BaseLink
                              locale={router.locale}
                              href={url ?? '/'}
                              type={LinkType.Menu}
                              iconName='chevronRight'
                              onClick={() => setIsMegaMenuActive(false)}
                            >
                              {/* TODO: Add a link to website ver of selected language */}
                              {subpage.elements.summaryTitle.value}
                            </BaseLink>
                          )}
                        </Box>
                      </Flex>
                    )}
                  </ListItem>
                );
              })
            ) : (
              <UnorderedList
                listStyleType={'none'}
                margin='0'
                pt={{ base: 'xs', lg: 'unset' }}
                opacity={{ base: '1', lg: !isChangingViewsExploreBy ? '1' : '0' }}
                transition='opacity 0.3s'
              >
                <Text
                  // as='h2'
                  // pb='s'
                  pl='24px'
                  // display={{ base: 'none', lg: 'block' }}
                  // textStyle={{ base: 'mobile.h6', lg: 'desktop.h6' }}
                  as='h3'
                  pb='xs'
                  display={{ base: 'none', lg: 'block' }}
                >
                  {'Explore by theme'}:
                </Text>
                {theme?.map((tag, index) => {
                  // let slug = slugify(translations.multiple(linkToOverviewPage) + '/' + tag.name.toLowerCase().replaceAll('&', 'and').replace(/ /g,'-')
                  // console.log("slug",slugify(translations.multiple(linkToOverviewPage) + '/' + tag.name.toLowerCase().replaceAll('&', 'and').replace(/ /g,'-'))
                  return (
                    <ListItem key={index} textStyle='desktop.body.book.l' pb='s'>
                      <BaseLink
                        locale={router.locale}
                        href={'/' + slugify(translations.multiple(linkToOverviewPage), { lower: true })}
                        type={LinkType.Menu}
                        iconName='chevronRight'
                        onClick={() => {
                          cacheData.put('insight_theme', tag.codename);
                          setIsMegaMenuActive(false);
                        }}
                      >
                        {/* TODO: Add a link to website ver of selected language */}
                        {translations.theme_topic(tag.codename)}
                      </BaseLink>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            )}
            {/* ---- Link to overview pages ---- */}
            {!!linkToOverviewPage && (
              <ListItem textStyle='desktop.body.book.l' pb='s'>
                {/* TODO: ANTE, UPDATE THE KONTENT.AI MODEL TO BE SINGULAR CODENAME AFTER MERGE */}
                <BaseLink
                  href={'/' + slugify(translations.multiple(linkToOverviewPage), { lower: true })}
                  locale={router.locale}
                  type={LinkType.SecondaryButton}
                  colorScheme='cyan.web'
                  iconName='chevronRightForLink'
                  onClick={() => {
                    setIsMegaMenuActive(false);
                    //cacheData.del('insight_theme');
                    cacheData.put('insight_theme', null);
                  }}
                >
                  {/* TODO: Add a link to website ver of selected language */}
                  {linkToOverviewPage === 'career'
                    ? translations.common('openPositions')
                    : router.locale == 'en'
                    ? translations.common('seeOurFeaturedOverview', {
                        overviewTypeMultiple: translations.multiple(linkToOverviewPage),
                      })
                    : translations.common('seeOurLatestOverview', {
                        overviewTypeMultiple: translations.multiple(linkToOverviewPage),
                      })}
                </BaseLink>
              </ListItem>
            )}
            {/* ---- Link to overview pages END ---- */}
          </UnorderedList>
        </Box>
        {/* ------------ Third column END ------------ */}

        {/* ------------ Fourth column ------------ */}
        <Box
          flex='1'
          w={{
            base: '100vw',
            lg: subpages[0].system.type === 'navigation_category' ? '25%' : '25%',
          }}
          h={{ base: '100%', lg: 'auto' }}
          pl='30px'
          pb={{ base: 'l', lg: 'unset' }}
          pr={{ base: 'xs', lg: 'unset' }}
          sx={{
            '@media screen and (max-width: 1024px)': {
              h: '100%',
            },
          }}
          // sx={{
          //   '::-webkit-scrollbar': {
          //     width: '4px',
          //   },
          //   '::-webkit-scrollbar-track': {
          //     width: '6px',
          //   },
          //   '::-webkit-scrollbar-thumb': {
          //     background: 'mountain.20',
          //     borderRadius: '0',
          //   },

          //   scrollbarColor: 'var(--chakra-colors-mountain-20) white',
          //   scrollbarWidth: 'thin',
          //   scrollbarGutter: 'stable both-edges',
          // }}
        >
          <Box pb='xs' borderBottom={'1px solid'} borderColor='#D4D7D9' display={{ base: 'block', lg: 'none' }} pt='xs'>
            <Breadcrumb
              name={activeExploreBy && activeExploreBy}
              onClick={() => {
                setMegaMenuIndexMobile(1);
              }}
            />
          </Box>
          <UnorderedList
            className='fourth-unordered-list'
            listStyleType={'none'}
            margin='0'
            pt={{ base: 'xs', lg: 'unset' }}
            opacity={{ base: '1', lg: !isChangingViewsExploreBy ? '1' : '0' }}
            transition='opacity 0.3s'
            h={{ base: 'calc(100% - 50px)', lg: 'auto' }}
            // h='calc(100%-50px)'
            overflowY={'auto'}
            // alignSelf={'stretch'}
            sx={{
              '::-webkit-scrollbar': {
                width: '4px',
              },
              '::-webkit-scrollbar-track': {
                width: '6px',
              },
              '::-webkit-scrollbar-thumb': {
                background: 'mountain.20',
                borderRadius: '0',
              },

              scrollbarColor: 'var(--chakra-colors-mountain-20) white',
              scrollbarWidth: 'thin',
              scrollbarGutter: 'stable both-edges',
            }}
          >
            {exploreByPages?.[0] &&
              exploreByPages.map((page: ContentPage, index) => {
                return page.elements.partOfCampaignPage.value.length > 0 && page.system.type == 'content_page' ? (
                  <ListItem key={index} textStyle='desktop.body.book.l' pb='s'>
                    <BaseLink
                      locale={router.locale}
                      // href={normalize(page, undefined, undefined, false, translations)?.[page.system.codename] ?? '/'}
                      href={hrefLink(
                        page,
                        (slug as unknown as string)?.split('/'),
                        true,
                        translations.locale,
                        translations,
                      )}
                      type={LinkType.Menu}
                      iconName='chevronRight'
                      onClick={() => {
                        cacheData.put('insight_theme', null);

                        setIsMegaMenuActive(false);
                      }}
                    >
                      {/* TODO: Add a link to website ver of selected language */}
                      {page.elements.summaryTitle.value}
                    </BaseLink>
                  </ListItem>
                ) : (
                  <ListItem key={index} textStyle='desktop.body.book.l' pb='s'>
                    <BaseLink
                      locale={router.locale}
                      href={normalize(page, undefined, undefined, false, translations)?.[page.system.codename] ?? '/'}
                      type={LinkType.Menu}
                      iconName='chevronRight'
                      onClick={() => {
                        cacheData.put('insight_theme', null);

                        setIsMegaMenuActive(false);
                      }}
                    >
                      {/* TODO: Add a link to website ver of selected language */}
                      {page.elements.summaryTitle.value}
                    </BaseLink>
                  </ListItem>
                );
              })}
            {/* ---- Link to overview pages ---- */}
            {exploreByOverviewPage !== '' && exploreByOverviewPage && exploreByPages && exploreByPages.length > 0 && (
              <ListItem textStyle='desktop.body.book.l' pb='s'>
                <BaseLink
                  href={'/' + translations.multiple(exploreByOverviewPage)}
                  locale={router.locale}
                  type={LinkType.SecondaryButton}
                  colorScheme='cyan.web'
                  iconName='chevronRightForLink'
                  onClick={() => {
                    cacheData.put('insight_theme', null);
                    setIsMegaMenuActive(false);
                  }}
                >
                  {/* TODO: Add a link to website ver of selected language */}
                  {translations.common('exploreMoreOverviewTypeMultiple', {
                    overviewTypeMultiple: translations.multiple(exploreByOverviewPage),
                  })}
                </BaseLink>
              </ListItem>
            )}
            {/* ---- Link to overview pages END ---- */}
          </UnorderedList>
        </Box>
        {/* ------------ Fourth column END ------------ */}
      </Flex>{' '}
    </>
  );
};
