import {
  Box,
  chakra,
  Container,
  Flex,
  Image,
  Link,
  ListItem,
  Select,
  // Skeleton,
  Text,
  UnorderedList,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { LocationPopup } from 'components/modules/LocationPopup';
import cacheData from 'memory-cache';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { handleLanguageChange } from 'utils/handleLocaleChange';
import { GetContentPage } from 'utils/sitemap';
import { shouldForceDefaultLocale } from 'utils/withForceLocale';

import { ProdClient } from '../../client/client';
import { LinkType } from '../../lib/enums/LinkType';
import {
  ContentPage,
  ContentPageActor,
  NavigationCategory,
  NavigationItem,
  NavigationLanguageItem,
  Search,
} from '../../models';
import { Navigation } from '../../models/content-types/navigation';
import { IconComp } from '../base/IconComp';
import { Link as BaseLink } from '../base/Link';
import MainSearch from './MainSearch';
import { MegaMenu } from './MegaMenu';

export type NavProps = {
  navProps: Navigation;
  megaMenuProps: {
    subpages: (ContentPage | NavigationCategory | NavigationLanguageItem | ContentPageActor)[];
    highlightedArticle: ContentPage | ContentPageActor | null;
  }[];
  languageSelectors: NavigationLanguageItem[];
  mainSearch: Search;
  isCampaign?: boolean;
  variants?: Awaited<ReturnType<typeof GetContentPage.allLocales>>;
  hasFrontpageCarousel?: boolean;
  shouldHideSearch?: boolean;
  RSTLangSwitch?: [];
};
const CustomLink = chakra<typeof NextLink, NextLinkProps>(NextLink, {
  // ensure that you're forwarding all of the required props for your case
  shouldForwardProp: (prop) =>
    ['href', 'ref', 'locale', 'colorScheme', 'type', 'iconName', 'onClick', 'as'].includes(prop),
});

export interface MegaMenuActiveTabProps {
  isRST: any;
  subpages: any;
  highlightedArticle: any;
  categoryDescription: string;
  categoryTitle: string;
  linkToOverview: string;
  variants?: Awaited<ReturnType<typeof GetContentPage.allLocales>>;
}
export interface RSTMenuProps {
  isRST: any;
  subpages: any;
  variants?: Awaited<ReturnType<typeof GetContentPage.allLocales>>;
}

export const NavBar: FC<NavProps> = ({
  navProps,
  megaMenuProps,
  mainSearch,
  isCampaign,
  languageSelectors,
  variants,
  hasFrontpageCarousel = false,
}) => {
  const router = useRouter();

  const shouldUseDefaultLocale = shouldForceDefaultLocale(router);
  const translations = withTranslations();
  const NavLinks = navProps.elements.navigationTitles.linkedItems;
  const NavIcon = navProps.elements.logo?.value[0].url;
  let oldScrollValue = 0;
  const [isShowNav, setIsShowNav] = useState(true);
  const [isShowMainSearch, setIsShowMainSearch] = useState(false);
  const [megaMenuActiveTab, setMegaMenuActiveTab] = useState<MegaMenuActiveTabProps>();
  const rstLangMenu = {
    isRST: navProps.system.codename,
    subpages: megaMenuProps[0],
    variants,
  };
  const [isMegaMenuActive, setIsMegaMenuActive] = useState(false);
  const [megaMenuIndexMobile, setMegaMenuIndexMobile] = useState(0);
  const [cookie, setCookie] = useCookies(['NEXT_LOCALE']);
  const [isOnFrontpage, setIsOnFrontpage] = useState(hasFrontpageCarousel);
  const isRemovePadding = hasFrontpageCarousel;
  const shouldShowLanguageSelector = navProps.elements.showLanguageSelector.value[0]?.codename === 'yes';
  const [_initialLocaleCookie, _setInitialLocaleCookie, removeInitialLocaleCookie] = useCookies(['INITIAL_LOCALE']);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [insightTheme, setInsightTheme] = useState<ITaxonomyTerms[]>();
  const updateTheme = async () => {
    const response_data =
      cacheData.get('taxonomies') == null
        ? await ProdClient.taxonomies()
            .toPromise()
            .then((response) => {
              cacheData.put('taxonomies', response, 86400000, function () {
                console.log('clearing the cache');
                cacheData.del('taxonomies'); //to clear the existing cache after 24hr
              });
              return response;
            })
        : cacheData.get('taxonomies');
    // console.log("response data - ",response_data)
    response_data.data.items.map((item) => {
      if (item.system.codename == 'theme_topic') setInsightTheme(item.terms);
    });
  };
  useEffect(() => {
    updateTheme();
  }, []);
  useEffect(() => {
    // console.log(linkRef.current);
  }, [linkRef]);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (this: any, e) {
        e.preventDefault();
        setIsMegaMenuActive(false);
        document.querySelector(this?.getAttribute('href'))?.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  });

  const checkScroll = useCallback(() => {
    if (window.scrollY > oldScrollValue && window.scrollY >= 100) {
      setIsShowNav(false);
    } else {
      setIsShowNav(true);
    }

    if (document.getElementById('fronpage-hero-carousel') && window.scrollY >= 100) {
      setIsOnFrontpage(false);
    } else if (document.getElementById('fronpage-hero-carousel') && window.scrollY <= 100) {
      setIsOnFrontpage(true);
    }

    oldScrollValue = window.scrollY;
  }, []);

  const hideMenuEsc = useCallback((e: any) => {
    if (e.key === 'Escape') {
      setIsMegaMenuActive(false);
    }
  }, []);

  const hideMenuClick = useCallback((e: any) => {
    let isPartOfNav = false;
    e.composedPath().forEach((p: any) => {
      if (p.id === 'RambollNavBar') {
        isPartOfNav = true;
      }
    });
    if (!isPartOfNav) {
      setIsMegaMenuActive(false);
    }
  }, []);

  useEffect(() => {
    if (isMegaMenuActive) {
      document.documentElement.classList.add('scroll-disable');
      window.removeEventListener('scroll', checkScroll, true);
      window.addEventListener('keydown', hideMenuEsc, true);
      window.addEventListener('click', hideMenuClick, true);
    } else {
      document.documentElement.classList.remove('scroll-disable');
      window.addEventListener('scroll', checkScroll, true);
      window.removeEventListener('keydown', hideMenuEsc, true);
      window.removeEventListener('click', hideMenuClick, true);
    }

    return () => {
      window.removeEventListener('keydown', hideMenuEsc);
      window.removeEventListener('click', hideMenuClick);
      window.removeEventListener('scroll', checkScroll);
    };
  }, [isMegaMenuActive]);

  const toggleMainSearch = () => {
    setIsShowMainSearch((state) => !state);
    setIsMegaMenuActive(false);
  };

  const NavBarMobile = () => {
    return (
      <Flex
        w='100vw'
        display={{ base: 'flex', lg: 'none' }}
        as={'nav'}
        background={!isOnFrontpage ? 'white' : undefined}
        position='relative'
        zIndex={9}
        align={'center'}
        justify={'space-between'}
        p='xs'
        id='mobile-main-nav'
      >
        <VisuallyHidden as={'nav'}>{translations.aria('navigationBar')}</VisuallyHidden>
        <Box
          as={NextLink}
          href={'/'}
          // TODO: Needs to be a fix for translation here
          aria-label={translations.aria('goToHomepage')}
        >
          <Box
            w='147px'
            aria-hidden={true}
            filter={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'brightness(0) invert(1)' : 'unset'}
          >
            <Image src={NavIcon} />
          </Box>
        </Box>
        <Flex gap='50px' flexDirection='row-reverse' pr='xs'>
          {!!NavLinks.length && (
            <Box
              as={'button'}
              display='flex'
              alignContent='center'
              onClick={() => {
                if (!isMegaMenuActive) {
                  setMegaMenuIndexMobile(0);
                } else {
                  setTimeout(() => {
                    setMegaMenuIndexMobile(0);
                  }, 750);
                }
                setIsMegaMenuActive(!isMegaMenuActive);
                setIsShowMainSearch(false);
              }}
            >
              <IconComp
                iconName='menu'
                chakraStyle={{
                  display: 'flex',
                  filter: isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'brightness(0) invert(1)' : 'unset',
                }}
              />
              <VisuallyHidden>{translations.aria('openMenu')}</VisuallyHidden>
            </Box>
          )}
          {!isCampaign && (
            <Box
              as={'button'}
              aria-label={translations.aria('search')}
              filter={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'brightness(0) invert(1)' : 'unset'}
              onClick={toggleMainSearch}
            >
              <IconComp iconName='search' />
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };
  const NavBarDesktop = () => {
    return (
      <Box
        display={{ base: 'none', lg: 'flex' }}
        as={'nav'}
        position='relative'
        h={'100px'}
        m='auto'
        paddingInlineStart='0'
        paddingInlineEnd='0'
        zIndex={9}
        alignItems={'center'}
        justifyContent={'space-between'}
        pl={'s'}
        pr={'s'}
      >
        <VisuallyHidden as={'nav'}>{translations.aria('navigationBar')}</VisuallyHidden>
        <Box
          as={NextLink}
          href={'/'} // TODO: Needs to be a fix for translation here and be main site url
          aria-label={translations.aria('goToHomepage')}
        >
          <Box
            w='147px'
            aria-hidden={true}
            filter={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'brightness(0) invert(1)' : 'unset'}
          >
            <Image src={NavIcon} />
          </Box>
        </Box>
        <Flex>
          {isCampaign ? <CampaignNavBarItems /> : <NavBarItems />}
          {process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION !== 'default' ? (
            <Link
              isExternal
              data-group
              href='https://www.ramboll.com'
              py={{ base: 'xs' }}
              pl={{ lg: 'xs' }}
              _hover={{
                textDecoration: 'underline',
                color: isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'white' : 'cyan.web',
              }}
              display='flex'
              alignItems='center'
              textStyle={{ base: 'mobile.h3', lg: 'desktop.body.book.s' }}
            >
              <IconComp
                iconName='home'
                chakraStyle={{
                  display: { base: 'none', lg: 'block' },
                  _groupHover: {
                    path: {
                      stroke: 'cyan.web',
                    },
                  },
                }}
              />
              {translations.common('visitRamboll')}
            </Link>
          ) : null}
        </Flex>
      </Box>
    );
  };
  const NavBarItems = () => {
    return (
      <UnorderedList
        listStyleType={'none'}
        display='flex'
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ lg: 'space-evenly' }}
        margin='0'
        pt={{ base: 'm', lg: '0' }}
        w={{ base: 'calc(100vw - 40px)', lg: 'auto' }}
        id='unorder'
        sx={{
          '@media screen and (max-width: 1024px)': {
            pt: '20px',
          },
        }}
      >
        {mapNavLinks()}
        {!isCampaign && (
          <ListItem
            mx={{ lg: '30px' }}
            display={{ base: 'none', lg: 'flex' }}
            sx={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? { svg: { path: { fill: 'white' } } } : {}}
          >
            <Box as={'button'} onClick={toggleMainSearch}>
              <IconComp iconName='search' />
            </Box>
          </ListItem>
        )}
        <Flex
          // w='100vw'
          display={{ base: 'flex', lg: 'none' }}
          as={'nav'}
          //   background={!isOnFrontpage ? 'pebble.40' : undefined}
          position='relative'
          zIndex={9}
          align={'center'}
          justify={'space-between'}
          id='mobile-list-office'
        >
          {/* Link to list of offices */}
          <BaseLink
            locale={cookie.NEXT_LOCALE}
            href={`/${translations.multiple('contactUsUrl')}`}
            colorScheme='cyan.web'
            type={LinkType.SecondaryButton}
            iconName='chevronRightForLink'
            onClick={() => setIsMegaMenuActive(false)}
          >
            {/* TODO: This needs to come from Kontent.ai & have HREF */}
            {translations.common('viewListOfOffices', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
          </BaseLink>
        </Flex>
      </UnorderedList>
    );
  };

  const url = router.locale == 'en' ? 'sv-se' : 'en';
  const mapNavLinks = () => {
    return NavLinks.map((link: any, index) => {
      const isLanguageSwitcher =
        link.system.type === 'navigation_item' &&
        (link as NavigationItem).elements.languageSwitcher.value[0]?.codename === 'yes';
      return link.system.type === 'navigation_item' && navProps.system.codename !== 'rst_navigation' ? (
        <ListItem
          key={link.elements.title.value}
          display='flex'
          alignItems='center'
          w={{ base: 'auto', lg: 'auto' }}
          pb={{ base: 's', lg: 0 }}
          mx={{ lg: '30px' }}
          color={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'white' : 'black'}
          _hover={{
            borderBottom: { lg: '1px solid' },
            borderColor: isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'white' : 'cyan.web',
            color: isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'white' : 'cyan.web',
            svg:
              isOnFrontpage && !isMegaMenuActive && !isShowMainSearch
                ? { path: { fill: 'white' } }
                : { path: { fill: 'cyan.web' } },
          }}
          sx={
            megaMenuActiveTab?.categoryTitle === link.elements.title.value && isMegaMenuActive
              ? {
                  '@media only screen and (min-width: 1024px)': {
                    color: 'cyan.web',
                    borderBottom: '1px solid',
                    borderColor: 'cyan.web',
                    svg: {
                      path: {
                        fill: 'cyan.web',
                      },
                    },
                  },
                }
              : isOnFrontpage && !isMegaMenuActive && !isShowMainSearch
              ? { svg: { path: { fill: 'white' } } }
              : {}
          }
        >
          <Text
            suppressHydrationWarning
            as={'button'}
            textAlign='left'
            w={{ base: '100vw', lg: 'auto' }}
            textStyle={{ base: 'mobile.h3', lg: 'mobile.body.s' }}
            display='flex'
            alignItems='center'
            gap={{ base: 'xxs', lg: 0 }}
            aria-expanded={!isMegaMenuActive}
            aria-controls='RambollMegaMenu'
            aria-label={`${translations.aria('openMenuOnTab')}: ${link.elements.title.value}`}
            onClick={() => {
              setMegaMenuIndexMobile(1);
              setMegaMenuActiveTab({
                isRST: navProps.system.codename,
                linkToOverview: link.elements.linkToOverview.value[0]?.codename,
                categoryDescription: link.elements.description.value,
                categoryTitle: link.elements.title.value,
                variants,
                ...megaMenuProps[index],
              });

              // This should not happen on the mobile version
              if (
                megaMenuActiveTab?.categoryTitle === link.elements.title.value &&
                isMegaMenuActive &&
                window.innerWidth >= 1024
              ) {
                setIsMegaMenuActive(false);
              } else {
                if (isShowMainSearch) {
                  setIsShowMainSearch(false);
                }
                setIsMegaMenuActive(true);
              }
            }}
          >
            {isLanguageSwitcher ? (
              <Box
                suppressHydrationWarning
                display='flex'
                gap={{ base: 'xxs', lg: 0 }}
                alignItems='center'
                borderTop={{ base: '1px solid', lg: 'none' }}
                borderColor='mountain.10'
                pt={{ base: 's', lg: '0' }}
                w={{ base: '100%' }}
                mr={{ base: 'xxs', lg: '0' }}
              >
                <IconComp iconName='pin' />
                {
                  languageSelectors.find(
                    (item) => item.elements.languageCodename.value === (router.locale ?? translations.locale),
                  )?.elements.title.value
                }
              </Box>
            ) : (
              <>{link.elements.title.value}</>
            )}
          </Text>
        </ListItem>
      ) : link.system.type === 'navigation_item' && navProps.system.codename === 'rst_navigation' ? (
        <>
          <Select
            id='second'
            borderWidth='0px'
            textStyle='desktop.body.book.s'
            textColor='mountain'
            aria-label={translations.aria('languageSelector')}
            name={translations.aria('languageSelector')}
            w={'fit-content'}
            value={cookie.NEXT_LOCALE}
            onChange={() => {
              linkRef.current?.click();
            }}
          >
            {['Global', 'Sweden'].map((item) => {
              return (
                <option key={index} value={item == 'Global' ? 'en' : 'sv-se'}>
                  {item}
                </option>
              );
            })}
          </Select>
        </>
      ) : link.system.type === 'anchor_link_navigation_item' ? (
        <ListItem key={index} px={{ base: 0, lg: 'xs' }} py={{ base: 'xs', lg: 0 }}>
          <Box
            as='a'
            _hover={{
              color: 'cyan.web',
            }}
            textStyle={{ base: 'mobile.h3', lg: 'desktop.body.book.s' }}
            href={`#${link.elements.component.value[0]}`}
          >
            {link.elements.name.value}
          </Box>
        </ListItem>
      ) : link.system.type === 'content_page' ? (
        <ListItem key={index} px={{ base: 0, lg: 'xs' }} py={{ base: 'xs', lg: 0 }} id='content_page'>
          <Box
            as='a'
            _hover={{
              color: 'cyan.web',
            }}
            // textStyle={{ base: 'mobile.h3', lg: 'desktop.body.medium.s' }}
            textStyle={{ base: 'mobile.h3', lg: 'mobile.body.s' }}
            href={
              Object.keys(
                normalize(
                  link,
                  cookie.NEXT_LOCALE ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
                  undefined,
                  true,
                  translations,
                ),
              )[0] ?? ''
            }
          >
            {link.elements.summaryTitle.value}
          </Box>
        </ListItem>
      ) : null;
    });
  };

  const CampaignNavBarItems = () => {
    return (
      <Flex
        // w='100%'
        // flexDirection={{ base: 'column', lg: 'row' }}
        // justifyContent={{ base: '', lg: 'space-between' }}
        // ml={{ base: '0', lg: 'm' }}
        // h='100%'
        // overflowY={{ base: 'scroll', lg: 'unset' }}
        listStyleType={'none'}
        display='flex'
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ lg: 'space-evenly' }}
        margin='0'
        pt={{ base: 'm', lg: '0' }}
        w={{ base: '100vw', lg: 'auto' }}
      >
        <UnorderedList
          listStyleType={'none'}
          display='flex'
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'start', lg: 'center' }}
          pt={{ base: 'xs', lg: '0' }}
          mb={{ base: '0', lg: 'unset' }}
          borderBottomColor='mountain.10'
          borderBottomWidth={{ base: '1px', lg: '0px' }}
          marginInlineStart={0}
          sx={{
            '@media screen and (max-width: 1024px)': {
              pt: '0',
              m: 'unset',
            },
          }}
        >
          {mapNavLinks()}
          <ListItem mx={{ lg: '30px' }} display={{ base: 'none', lg: 'flex' }}>
            <Box as={'button'} onClick={toggleMainSearch}>
              <IconComp iconName='search' />
            </Box>
          </ListItem>
        </UnorderedList>
        {/* <Flex flexDir={{ base: 'column', lg: 'row' }} gap='30px' alignItems={{ base: 'start', lg: 'center' }}>
        
        </Flex> */}
        <Flex flexDir={{ base: 'column', lg: 'row' }} gap='30px' alignItems={{ base: 'start', lg: 'center' }}>
          {shouldShowLanguageSelector && navProps.system.codename !== 'rst_navigation' && (
            <Select
              id='second'
              borderWidth='0px'
              textStyle='desktop.body.book.s'
              textColor='mountain'
              aria-label={translations.aria('languageSelector')}
              name={translations.aria('languageSelector')}
              w={'fit-content'}
              value={cookie.NEXT_LOCALE}
              onChange={(e) => handleLanguageChange(e.target.value, cookie, setCookie)}
            >
              {languageSelectors.map((language, index) => {
                return (
                  <option key={index} value={language.elements.languageCodename.value}>
                    {language.elements.languageName.value}
                  </option>
                );
              })}
            </Select>
          )}
        </Flex>
      </Flex>
    );
  };
  return (
    <>
      {/* {megaMenuProps} */}
      {megaMenuProps.length > 0 &&
        megaMenuProps.forEach(function (item) {
          const arrayItem = Object.keys(item);
          arrayItem !== null &&
            arrayItem.map((elem) => {
              if (item[elem] !== null && item[elem].length !== undefined) {
                item[elem].map((subItem) => {
                  subItem.elements?.url?.value;
                  subItem.elements?.url?.value && (
                    <VisuallyHidden aria-hidden='true'>
                      <Box
                        as={NextLink}
                        href={`/${subItem.elements?.url?.value}`}
                        aria-label={translations.aria('goToHomepage')}
                      ></Box>
                    </VisuallyHidden>
                  );
                });
              } else {
                item[elem] !== null && item[elem].elements?.url?.value && (
                  <VisuallyHidden aria-hidden='true'>
                    <Box
                      as={NextLink}
                      href={`/${item[elem].elements?.url?.value}`}
                      aria-label={translations.aria('goToHomepage')}
                    ></Box>
                  </VisuallyHidden>
                );
              }
              // let obj = JSON.parse(elem);
            });
        })}
      <VisuallyHidden>
        <CustomLink
          ref={linkRef}
          type={LinkType.Menu}
          href={`/${url}/${rstLangMenu.variants?.[url]}`.replaceAll(/\/+/g, '/')}
          locale={url}
          onClick={() => {
            handleLanguageChange(url, cookie, setCookie);
            removeInitialLocaleCookie('INITIAL_LOCALE');
            setIsMegaMenuActive(false);
          }}
        >
          {url == 'en' ? 'Global' : 'Sweden'}
        </CustomLink>
      </VisuallyHidden>
      <Box
        key={router.asPath}
        pt={{ base: isRemovePadding ? '0' : '70px', lg: isRemovePadding ? '0' : '100px' }}
        position={'relative'}
        id='RambollNavBar'
        zIndex={10}
        // boxShadow = {isMegaMenuActive ? '4px 4px 4px 0px #d4d7d9 inset' : 'none'}
      >
        {/* ---------------- NAV BAR ---------------- */}
        <Box
          background={
            isOnFrontpage && !isMegaMenuActive && !isShowMainSearch
              ? 'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)'
              : 'white'
          }
          transition='transform 1s ease, background 0.75s'
          transform={`translate(-50%, ${!isShowNav ? '-100%' : '0%'})`}
          position={'fixed'}
          top='0'
          left='50%'
          w='100%'
          maxW={{ base: '1440px', lg: moduleMaxWidth }}
          zIndex={10}
        >
          {languageSelectors.length > 1 && <LocationPopup languages={languageSelectors} variants={variants} />}
          <Box
            position={'relative'}
            background={
              isCampaign ? 'white' : isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'transparent' : 'white'
            }
            transition={isRemovePadding ? 'background 0.75s' : undefined}
            zIndex={10}
            // style={{ pointerEvents: router.locale != cookie['NEXT_LOCALE'] ? 'none' : 'auto' }}
          >
            <NavBarMobile />
            <NavBarDesktop />
          </Box>
          {/* ---------------- NAV BAR END ---------------- */}

          {/* ---------------- BACKGROUND SHADOW (OPEN MEGA MENU) ---------------- */}
          <Box
            w='100%'
            h='100vh'
            position='absolute'
            background='mountain.base'
            transition='opacity 0.75s'
            opacity={isMegaMenuActive ? '0.3' : 0}
            pointerEvents='none'
            // boxShadow='4px 4px 4px 0px mountain.20 inset'
          ></Box>
          {/* ---------------- BACKGROUND SHADOW (OPEN MEGA MENU) END ---------------- */}

          {/* ---------------- MEGA MENU ---------------- */}
          <Flex
            bg={'white'}
            boxShadow='4px 4px 4px 0px #d4d7d9 inset'
            opacity={{ lg: isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 0 : !isMegaMenuActive ? 0 : 1 }}
            position='absolute'
            zIndex={8}
            w={{ base: '100vw', lg: 'auto' }}
            h={{ base: 'calc(100vh - 75px)', lg: 'auto' }}
            sx={{
              '@media screen and (width: 1024px)': {
                h: 'calc(100vh - 75px)',
                overflow: 'auto',
              },
              '@media screen and (max-height: 900px) and (min-width: 1024px)': {
                h: 'calc(100vh - 75px)',
                overflow: 'auto',
              },
            }}
            // overflow={'auto'}
            transition={
              isOnFrontpage
                ? 'transform 0.75s, opacity 0.75s'
                : !isMegaMenuActive
                ? 'transform 0.75s, opacity 0s 0.75s'
                : 'transform 0.75s'
            }
            transform={{
              base: !isMegaMenuActive
                ? `translateY(-200%) translateX(-${100 * megaMenuIndexMobile}vw)`
                : `translateY(0) translateX(-${100 * megaMenuIndexMobile}vw)`,
              lg: !isMegaMenuActive ? 'translateY(-100%)' : 'translateY(0)',
            }}
          >
            <Box
              w={{ base: '100%', lg: '100%' }}
              flex='0 0 100%'
              display={{ base: 'inline-block', lg: 'none' }}
              px='xs'
              overflowY={{ base: 'auto', lg: 'unset' }}
              pb={{ base: 'l', lg: 'unset' }}
            >
              {isCampaign ? <CampaignNavBarItems /> : <NavBarItems />}
            </Box>
            <Container
              display={isOnFrontpage && !isMegaMenuActive ? 'none' : 'unset'}
              w={{ base: 'unset' }}
              paddingInlineStart={{ base: '0', md: '0', lg: '0' }}
              paddingInlineEnd={{ base: '0', md: '0', lg: '0' }}
              marginInlineStart='0'
              marginInlineEnd='0'
              // height='100%'
            >
              {megaMenuActiveTab !== undefined && (
                <MegaMenu
                  setIsMegaMenuActive={setIsMegaMenuActive}
                  megaMenuProps={megaMenuActiveTab}
                  setMegaMenuIndexMobile={setMegaMenuIndexMobile}
                  theme={insightTheme}
                />
              )}
            </Container>
          </Flex>
          {/* ---------------- MEGA MENU ---------------- */}

          {/* ---------------- SEARCH ---------------- */}
          {/* {!isCampaign && ( */}
          <Flex
            bg={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'transparent' : 'white'}
            transition={'background 0.75s, transform 0.75s, opacity 0.75s'}
            position='absolute'
            transform={!isShowMainSearch ? 'translateY(-100%)' : 'translateY(0)'}
            w='100%'
            opacity={isOnFrontpage && !isShowMainSearch ? '0' : '1'}
          >
            {/* {!isCampaign && ( */}
            <Flex
              bg={isOnFrontpage && !isMegaMenuActive && !isShowMainSearch ? 'transparent' : 'white'}
              transition={
                isOnFrontpage
                  ? 'background 0.75s, transform 0.75s, opacity 0.75s'
                  : !isShowMainSearch
                  ? 'transform 0.75s, opacity 0s 0.75s'
                  : 'transform 0.75s'
              }
              position='absolute'
              transform={!isShowMainSearch ? 'translateY(-100%)' : 'translateY(0)'}
              w='100%'
              opacity={(isOnFrontpage && !isShowMainSearch) || !isShowMainSearch ? '0' : '1'}
            >
              <MainSearch placeholder={mainSearch?.elements.placeholder.value} toggleSearch={toggleMainSearch} />
            </Flex>
            {/* )} */}
          </Flex>
          {/* )} */}
          {/* ---------------- SEARCH END ---------------- */}
        </Box>
      </Box>
    </>
  );
};
