import fallbackImage from '../lib/fallbackImage.json';

// export const imageFormatterSetSrc = (frontifyJsonObject: string, aspectRatio?: number) => {
//   if (frontifyJsonObject) {
//     //In case of accidentally sending JSON object with multiple images
//     const isArray = JSON.parse(frontifyJsonObject).length;
//     const parsedObject = isArray ? JSON.parse(frontifyJsonObject)[isArray - 1] : JSON.parse(frontifyJsonObject);
//     const previewUrl = parsedObject.previewUrl;
//     // console.log('set - ',parsedObject)

//     const focalPoint = parsedObject.focalPoint
//       ? `crop=fp&fp=${parsedObject.focalPoint[0]},${parsedObject.focalPoint[1]}&`
//       : null;
//     return `${previewUrl}?width=${parsedObject.width}&height=${
//       aspectRatio ? 1920 / aspectRatio : `${parsedObject.height}`
//     }&${parsedObject.focalPoint ? focalPoint : ''},
//     `;
//   }
//   return undefined;
// };
export const imageFormatterSet = (frontifyJsonObject: string, aspectRatio?: number) => {
  if (frontifyJsonObject) {
    //In case of accidentally sending JSON object with multiple images
    const isArray = JSON.parse(frontifyJsonObject).length;
    const parsedObject = isArray ? JSON.parse(frontifyJsonObject)[isArray - 1] : JSON.parse(frontifyJsonObject);
    const previewUrl = parsedObject.previewUrl;
    // console.log('set - ',parsedObject)

    const focalPoint = parsedObject.focalPoint
      ? `crop=fp&fp=${parsedObject.focalPoint[0]},${parsedObject.focalPoint[1]}&`
      : null;
    return `${previewUrl}?width=640&height=${aspectRatio ? 640 / aspectRatio : '360'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 640w,
              ${previewUrl}?width=768&height=${aspectRatio ? 768 / aspectRatio : '432'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 768w,
              ${previewUrl}?width=900&height=${aspectRatio ? 900 / aspectRatio : '506'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 900w,
              ${previewUrl}?width=1024&height=${aspectRatio ? 1024 / aspectRatio : '576'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1024w,
              ${previewUrl}?width=1280&height=${aspectRatio ? 1280 / aspectRatio : '720'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1280w,
              ${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1440w,
              ${previewUrl}?width=1920&height=${aspectRatio ? 1920 / aspectRatio : '1080'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1920w, 
    `;
  }
  return undefined;
};

export const imageFormatterSetFullScreenImages = (frontifyJsonObject: string, aspectRatio?: number) => {
  if (frontifyJsonObject) {
    //In case of accidentally sending JSON object with multiple images
    const isArray = JSON.parse(frontifyJsonObject).length;
    const parsedObject = isArray ? JSON.parse(frontifyJsonObject)[isArray - 1] : JSON.parse(frontifyJsonObject);
    const previewUrl = parsedObject.previewUrl;
    const focalPoint = parsedObject.focalPoint
      ? `crop=fp&fp=${parsedObject.focalPoint[0]},${parsedObject.focalPoint[1]}&`
      : null;
    return `${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 640w,
    ${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 768w,
    ${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 900w,
    ${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1024w,
    ${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1280w,
              ${previewUrl}?width=1440&height=${aspectRatio ? 1440 / aspectRatio : '810'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1440w,
              ${previewUrl}?width=1920&height=${aspectRatio ? 1920 / aspectRatio : '1080'}&${
      parsedObject.focalPoint ? focalPoint : ''
    }format=webp 1920w, `;
  }
  return undefined;
};

export const imageFormatter = (
  frontifyJsonObject?: string,
  altText?: string,
  isNewsPage?: boolean,
  compressimage?: number,
): {
  src: string;
  width: number;
  height: number;
  alt: string;
  focalPoint: number[];
  quality: number;
} => {
  if (frontifyJsonObject) {
    try {
      const parsedObject = JSON.parse(frontifyJsonObject);
      const alt = altText ? altText : parsedObject.description;
      const compressim = compressimage ? compressimage : 75;
      // console.log("parsedObject formatter:  "+ parsedObject.description)
      // console.log("alt formatter:  "+ alt)
      // console.log("compressimage formatter:  "+ compressim)
      const previewUrl = parsedObject.previewUrl;
      // const focalPoint = parsedObject.focalPoint
      // ? `crop=fp&fp=${parsedObject.focalPoint[0]},${parsedObject.focalPoint[1]}`
      // : null;
      // if(parsedObject.focalPoint)  console.log(parsedObject,focalPoint,`${previewUrl}?${
      //       parsedObject.focalPoint ? focalPoint : ''
      //     }`)
      // const url = imageFormatterSetSrc(frontifyJsonObject);
      //  console.log('url - ',url)
      return {
        src: previewUrl,
        width: parsedObject.width || 1920,
        height: parsedObject.height || 1080,
        alt,
        focalPoint: parsedObject.focalPoint ?? [0.5, 0.5],
        quality: compressim ? compressim : 75,
      };
    } catch {
      if (!isNewsPage) {
        return {
          src: fallbackImage.previewUrl,
          width: fallbackImage.width,
          height: fallbackImage.height,
          alt: fallbackImage.description ?? '',
          focalPoint: fallbackImage.focalPoint,
          quality: compressimage ? compressimage : 75,
        };
      }

      return {
        src: '',
        width: 0,
        height: 0,
        alt: '',
        focalPoint: [],
        quality: compressimage ? compressimage : 75,
      };
    }
  }
  if (!isNewsPage) {
    return {
      src: fallbackImage.previewUrl,
      width: fallbackImage.width,
      height: fallbackImage.height,
      alt: fallbackImage.description ?? '',
      focalPoint: fallbackImage.focalPoint,
      quality: compressimage ? compressimage : 75,
    };
  }

  return {
    src: '',
    width: 0,
    height: 0,
    alt: '',
    focalPoint: [],
    quality: compressimage ? compressimage : 75,
  };
};

export const imageFormatterAlt = (frontifyJsonObject: string, altText?: string) => {
  if (!altText && frontifyJsonObject) {
    const parsedObject = JSON.parse(frontifyJsonObject);
    const description = parsedObject.description;
    return description;
  }
  if (altText) return altText;
  return undefined;
};
